import { Tag, TagSuggestion } from 'react-tag-autocomplete';
import { MentionData } from '@draft-js-plugins/mention';
import { ContentState, EditorState, Modifier, SelectionState } from 'draft-js';

import {
  postsApiTypes,
  apAccountReducerTypes,
  calendarApiTypes,
  mgApiTypes,
  mgReducerTypes,
  postsContainerTypes,
  postsReducerTypes,
  commonProps,
  apAccountContainerTypes,
  apFranReducerTypes
} from 'types';
import {
  dateObjBasedOnTimeZone,
  calendarInitScheduledTime,
  getTodayDate,
  getMomentBasedOnTimezone,
  formatDate,
  convertMomentToDate,
  getLocMediaCloudinaryIdsForAI,
  convertStringToEditorState,
  createEmptyEditorState,
  splitTextAndHashtagsFromText,
  generateOptionsForReactTag,
  getFormatBasedOnExactDate
} from 'utils/helpers';
import {
  COMMA_SPLITTER,
  COMMON_SOCIAL_PROVIDER_TYPE,
  ADD_POST_STATUS_NAME,
  USER_OWNERSHIP,
  MG_MEDIA_TYPE,
  CALENDAR_YR_MN_DATE_FORMAT,
  POSTS_STATUS_TYPE,
  PostUsageType,
  AIPostType,
  ADD_POST_CONTENT_MAX_TEXT_LENGTH,
  LOCATION_TIME_ZONE_DATE_TIME_FORMAT
} from 'utils/constants';

export const checkSiteRestriction = (userOwnership: string, menuAccess: any, restriction: string) => {
  if (userOwnership && menuAccess) {
    const siteRestrictions = menuAccess?.site_restrictions;
    if (userOwnership === USER_OWNERSHIP.ACCOUNT && siteRestrictions) {
      return siteRestrictions.location_user?.includes(restriction);
    } else if (userOwnership === USER_OWNERSHIP.FRANCHISOR && siteRestrictions) {
      return siteRestrictions.hub_user?.includes(restriction);
    }
  }
  return false;
};

/** Get posted text with FB tagged name based on FB tagging ID */
export const getPostedTextWithFBTagging = (postText: string, facebookTagList: MentionData[] = []) => {
  return facebookTagList.length
    ? facebookTagList.reduce((acc, curr) => {
        if (acc && acc.includes(`@[${curr.id}]`)) acc = acc.replace(`@[${curr.id}]`, `@${curr.name}`);
        return acc;
      }, postText)
    : postText;
};

/** Get updated post details for creator location view */
export const getAccountUpdatedPostDetails = (
  activePlatforms: string[],
  postDetails: postsApiTypes.IPostDetailSavedPost | postsApiTypes.IPostDetailContentPost,
  savedHashtags?: string[],
  mediaList?: mgApiTypes.IMGListResponseObj[]
) => {
  const isFacebook = isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK);
  const isTwitter = isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER);
  const isLinkedin = isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN);
  const isTiktok = isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK);
  const isInstagram = isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM);
  const isGoogle = isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE);
  const isPinterest = isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST);

  const socialMediaTextObj = {
    facebook: getPostedTextWithFBTagging(postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK), postDetails.page_tag_details?.facebook),
    twitter: postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER, savedHashtags || []),
    linkedin: postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN, savedHashtags || []),
    instagram: postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM, savedHashtags || []),
    tiktok: postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK, savedHashtags || []),
    google: postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE, savedHashtags || []),
    pinterest: postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST, savedHashtags || [])
  };
  return {
    ...postDetails,
    facebook_text: isFacebook ? (postDetails.use_facebook ? socialMediaTextObj.facebook : '') : '',
    twitter_text: isTwitter ? (postDetails.use_twitter ? socialMediaTextObj.twitter : '') : '',
    linkedin_text: isLinkedin ? (postDetails.use_linkedin ? socialMediaTextObj.linkedin : '') : '',
    instagram_text: isInstagram ? (postDetails.use_instagram ? socialMediaTextObj.instagram : '') : '',
    tiktok_text: isTiktok ? (postDetails.use_tiktok ? socialMediaTextObj.tiktok : '') : '',
    pinterest_text: isPinterest ? (postDetails.use_pinterest ? socialMediaTextObj.pinterest : '') : '',
    google_text: isGoogle ? (postDetails.use_google ? socialMediaTextObj.google : '') : '',
    use_facebook: Boolean(isFacebook && postDetails.use_facebook),
    use_instagram: Boolean(isInstagram && postDetails.use_instagram && socialMediaTextObj.instagram.length <= ADD_POST_CONTENT_MAX_TEXT_LENGTH.INSTAGRAM && mediaList?.length),
    use_twitter: Boolean(isTwitter && postDetails.use_twitter && socialMediaTextObj.twitter.length <= ADD_POST_CONTENT_MAX_TEXT_LENGTH.TWITTER),
    use_linkedin: Boolean(isLinkedin && postDetails.use_linkedin && socialMediaTextObj.linkedin.length <= ADD_POST_CONTENT_MAX_TEXT_LENGTH.LINKEDIN),
    use_tiktok: Boolean(isTiktok && postDetails.use_tiktok && mediaList?.[0]?.media_type === MG_MEDIA_TYPE.VIDEO),
    use_google: Boolean(isGoogle && postDetails.use_google && socialMediaTextObj.google.length <= ADD_POST_CONTENT_MAX_TEXT_LENGTH.GOOGLE),
    use_pinterest: Boolean(isPinterest && postDetails.use_pinterest && socialMediaTextObj.pinterest.length <= ADD_POST_CONTENT_MAX_TEXT_LENGTH.PINTEREST)
  };
};

/** Get edit or schedule data for create post */
export const getEditOrSchedulePost = ({
  activePlatforms,
  postDetails,
  accountTzInfoName,
  postPlatform,
  isCalendarView = false,
  savedHashtags,
  mediaList
}: apAccountContainerTypes.IGetEditOrSchedulePostProps): apAccountReducerTypes.IAPSavedPostDetails => {
  const isFacebook = isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK);
  const isTwitter = isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER);
  const isLinkedin = isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN);
  const isInstagram = isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM);
  const isGoogle = isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE);
  const isTiktok = isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK);
  const isPinterest = isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST);

  const updatedSocialMediaText = calendarViewSetPlatformText(postDetails, isCalendarView, isFacebook, isTwitter, isLinkedin, isInstagram, isGoogle, isTiktok, isPinterest, savedHashtags);
  return {
    current_tab: postPlatform
      ? postPlatform === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE_ACCOUNT
        ? COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE
        : postPlatform && [COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_BUSINESS_PAGE, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_PUBLIC_CONTENT].includes(postPlatform)
        ? COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
        : postPlatform
      : Boolean(isFacebook && postDetails.use_facebook)
      ? COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
      : Boolean(isInstagram && postDetails.use_instagram)
      ? COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
      : Boolean(isTwitter && postDetails.use_twitter)
      ? COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
      : Boolean(isLinkedin && postDetails.use_linkedin)
      ? COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN
      : Boolean(isGoogle && postDetails.use_google)
      ? COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE
      : Boolean(isTiktok && postDetails.use_tiktok)
      ? COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK
      : Boolean(isPinterest && postDetails.use_pinterest)
      ? COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST
      : activePlatforms[0],
    ...calendarViewSetUsePlatform(postDetails, isCalendarView, isFacebook, isTwitter, isLinkedin, isInstagram, isGoogle, isTiktok, isPinterest, updatedSocialMediaText, mediaList || []),
    ...updatedSocialMediaText,
    mobile_app_only_enabled: postDetails.mobile_app_only_enabled || false,
    locked: postDetails.locked || false,
    link_index:
      postDetails?.link_preview_image_url_choices?.length && postDetails?.link_preview_image_url
        ? postDetails?.link_preview_image_url_choices.findIndex((datum: string) => datum === postDetails?.link_preview_image_url)
        : 0,
    is_thumbnail_show: !postDetails?.link_preview_image_url ? false : true,
    status_name: postDetails.rejected
      ? ADD_POST_STATUS_NAME.REJECTED
      : postDetails.status_id === 1
      ? ADD_POST_STATUS_NAME.DRAFT
      : postDetails.status_id === 2
      ? ADD_POST_STATUS_NAME.AWAITING_APPROVAL
      : postDetails.status_id === 3
      ? ADD_POST_STATUS_NAME.APPROVED
      : postDetails.status_id === 5
      ? ADD_POST_STATUS_NAME.AWAITING_APPROVAL_AI_GENERATED
      : null,
    schedule_date_time: isCalendarView ? calendarInitScheduledTime(accountTzInfoName || '', postDetails.created_at) : dateObjBasedOnTimeZone(accountTzInfoName, postDetails?.start_date),
    account_schedule_date_time: dateObjBasedOnTimeZone(accountTzInfoName, postDetails?.start_date),
    campaigns: postDetails?.campaigns || [],
    instagram_reel: postDetails?.instagram_reel || false,
    facebook_reel: postDetails?.facebook_reel || false,
    google_call_to_action_type: postDetails?.google_call_to_action_type || null,
    google_call_to_action_url: postDetails?.google_call_to_action_url || null,
    facebook_call_to_action_type: postDetails?.facebook_call_to_action_type || null,
    facebook_call_to_action_link: postDetails?.facebook_call_to_action_link || null,
    personalized_facebook_call_to_action_link: postDetails?.personalized_facebook_call_to_action_link || '',
    link_preview_customized: postDetails?.link_preview_customized || false,
    video_cover_cloudinary_id: postDetails?.video_cover_cloudinary_id || null,
    facebook_story: postDetails?.facebook_story || false,
    instagram_story: postDetails?.instagram_story || false,
    pinterest_board_name: postDetails?.pinterest_board_name || null
  };
};

const calendarViewSetUsePlatform = (
  postDetails: postsApiTypes.IPostDetailSavedPost | postsApiTypes.IPostDetailContentPost | calendarApiTypes.IGetScheduledPostResponse,
  isCalendarView: boolean,
  isFacebook: boolean,
  isTwitter: boolean,
  isLinkedin: boolean,
  isInstagram: boolean,
  isGoogle: boolean,
  isTiktok: boolean,
  isPinterest: boolean,
  socialMediaText: commonProps.ICommonObj<string>,
  mediaList: mgApiTypes.IMGListResponseObj[]
) => {
  if (isCalendarView) {
    return {
      use_facebook: postDetails.use_facebook,
      use_instagram: postDetails.use_instagram,
      use_twitter: postDetails.use_twitter,
      use_linkedin: postDetails.use_linkedin,
      use_google: postDetails.use_google,
      use_tiktok: postDetails.use_tiktok,
      use_pinterest: postDetails.use_pinterest
    };
  } else {
    return {
      use_facebook: Boolean(isFacebook && postDetails.use_facebook),
      use_instagram: Boolean(isInstagram && postDetails.use_instagram && socialMediaText.instagram_text.length <= ADD_POST_CONTENT_MAX_TEXT_LENGTH.INSTAGRAM && mediaList.length),
      use_twitter: Boolean(isTwitter && postDetails.use_twitter && socialMediaText.twitter_text.length <= ADD_POST_CONTENT_MAX_TEXT_LENGTH.TWITTER),
      use_linkedin: Boolean(isLinkedin && postDetails.use_linkedin && socialMediaText.linkedin_text.length <= ADD_POST_CONTENT_MAX_TEXT_LENGTH.LINKEDIN),
      use_google: Boolean(isGoogle && postDetails.use_google && socialMediaText.google_text.length <= ADD_POST_CONTENT_MAX_TEXT_LENGTH.GOOGLE),
      use_tiktok: Boolean(isTiktok && postDetails.use_tiktok && mediaList?.[0]?.media_type === MG_MEDIA_TYPE.VIDEO),
      use_pinterest: Boolean(isPinterest && postDetails.use_pinterest && socialMediaText.pinterest_text.length <= ADD_POST_CONTENT_MAX_TEXT_LENGTH.PINTEREST)
    };
  }
};

const calendarViewSetPlatformText = (
  postDetails: postsApiTypes.IPostDetailSavedPost | postsApiTypes.IPostDetailContentPost | calendarApiTypes.IGetScheduledPostResponse,
  isCalendarView: boolean,
  isFacebook: boolean,
  isTwitter: boolean,
  isLinkedin: boolean,
  isInstagram: boolean,
  isGoogle: boolean,
  isTiktok: boolean,
  isPinterest: boolean,
  savedHashtags?: string[]
) => {
  if (isCalendarView) {
    return {
      facebook_text: postDetails.use_facebook
        ? postDetails?.facebook_story
          ? ''
          : getPostedTextWithFBTagging(postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK), postDetails.page_tag_details?.facebook) || ''
        : '',
      twitter_text: postDetails.use_twitter ? postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER) : '',
      linkedin_text: postDetails.use_linkedin ? postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN) : '',
      instagram_text: postDetails.use_instagram ? (postDetails?.instagram_story ? '' : postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM)) : '',
      google_text: postDetails.use_google ? postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE) : '',
      tiktok_text: postDetails.use_tiktok ? postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK) : '',
      pinterest_text: postDetails.use_pinterest ? postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST) : ''
    };
  } else {
    return {
      facebook_text: isFacebook
        ? postDetails.use_facebook
          ? getPostedTextWithFBTagging(postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, savedHashtags || []), postDetails.page_tag_details?.facebook) || ''
          : ''
        : '',
      twitter_text: isTwitter ? (postDetails.use_twitter ? postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER, savedHashtags || []) : '') : '',
      linkedin_text: isLinkedin ? (postDetails.use_linkedin ? postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN, savedHashtags || []) : '') : '',
      instagram_text: isInstagram ? (postDetails.use_instagram ? postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM, savedHashtags || []) : '') : '',
      google_text: isGoogle ? (postDetails.use_google ? postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE, savedHashtags || []) : '') : '',
      tiktok_text: isTiktok ? (postDetails.use_tiktok ? postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK, savedHashtags || []) : '') : '',
      pinterest_text: isPinterest ? (postDetails.use_pinterest ? postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST, savedHashtags || []) : '') : ''
    };
  }
};

export const getUpdatedTagsList = (tagList: string) => {
  return tagList.split(COMMA_SPLITTER).reduce((acc: Tag[], curr) => {
    if (curr) acc = [...acc, { value: Math.floor(Math.random() * 100), label: curr }];
    return acc;
  }, []);
};

/** Get media data for create post */
export const getAddMediaListForCreator = (
  postDetails: postsApiTypes.IPostDetailSavedPost | postsApiTypes.IPostDetailContentPost | calendarApiTypes.IGetScheduledPostResponse,
  accountId: number,
  isAiContentGenerated?: boolean
): mgApiTypes.IMGListResponseObj[] => {
  let mediaList: mgApiTypes.IMGListResponseObj[] = [];
  if (postDetails?.video_url) {
    mediaList = [
      {
        account_id: accountId,
        cloudinary_id: postDetails.cloudinary_video_id || null,
        comments: null,
        copy_to_descendants: false,
        created_at: '',
        created_by_user_id: 0,
        created_by_user_name: '',
        copied_from_account_name: null,
        franchisor_id: null,
        id: 1,
        liked: null,
        md5: null,
        medium_size_url: postDetails.video_url,
        name: '',
        produced_by: null,
        tags_list: '',
        thumbnail_url: postDetails?.video_thumbnail_url || '',
        video_cover_cloudinary_id: postDetails?.video_cover_cloudinary_id || null,
        transformations: null,
        trashed: false,
        url: postDetails.video_url,
        franchisor_name: null,
        account_name: null,
        account_address: null,
        cloudinary_resource_type: MG_MEDIA_TYPE.VIDEO,
        media_type: MG_MEDIA_TYPE.VIDEO,
        used_count: 0,
        created_user: {
          id: 0,
          name: '',
          profile_photo_url: null
        },
        media_release: false,
        brand_id: 0,
        brand_name: '',
        source: '',
        updated_at: '',
        verticals_list: '',
        created_user_profile_photo_url: null,
        original_franchisor_id: null,
        original_franchisor_name: null,
        expires_at: null,
        ai_compliance_validated_at: null,
        ai_compliance_warning: null
      }
    ];
  } else if (!postDetails?.link_url && postDetails?.photo_urls?.length) {
    mediaList = postDetails.photo_urls.reduce((acc: mgApiTypes.IMGListResponseObj[], curr, index) => {
      acc = [
        ...acc,
        {
          account_id: accountId,
          cloudinary_id: isAiContentGenerated && curr ? getLocMediaCloudinaryIdsForAI({ imageList: [curr], videoUrl: null })[0] || null : null,
          comments: null,
          copy_to_descendants: false,
          created_at: '',
          created_by_user_id: 0,
          created_by_user_name: '',
          copied_from_account_name: null,
          franchisor_id: null,
          id: index + 1,
          liked: null,
          md5: null,
          medium_size_url: curr,
          name: '',
          produced_by: null,
          tags_list: '',
          thumbnail_url: '',
          transformations: null,
          trashed: false,
          url: curr,
          franchisor_name: null,
          account_name: null,
          account_address: null,
          cloudinary_resource_type: '',
          media_type: MG_MEDIA_TYPE.PHOTO,
          used_count: 0,
          created_user: {
            id: 0,
            name: '',
            profile_photo_url: null
          },
          media_release: false,
          brand_id: 0,
          brand_name: '',
          source: '',
          updated_at: '',
          verticals_list: '',
          created_user_profile_photo_url: null,
          original_franchisor_id: null,
          original_franchisor_name: null,
          locked: false,
          // focus_score: null,
          expires_at: null,
          ai_compliance_validated_at: null,
          ai_compliance_warning: null
        }
      ];
      return acc;
    }, []);
  }
  return mediaList;
};

/** Get link data for create post */
export const getCreatorLinkData = (
  postDetails: postsApiTypes.IPostDetailSavedPost | postsApiTypes.IPostDetailContentPost | calendarApiTypes.IGetScheduledPostResponse
): calendarApiTypes.ICouponLinks => {
  return {
    id: postDetails.link_id || Math.floor(Math.random() * 100),
    link_preview_description: postDetails.link_preview_description || postDetails.link_description || '',
    link_preview_image_url: postDetails.link_preview_image_url || null,
    link_preview_image_url_choices: postDetails.link_preview_image_url_choices || [],
    link_preview_title: postDetails.link_preview_title || postDetails.link_title,
    short_url: '',
    tags_list: postDetails.tags_list,
    tracpoint_coupon_id: postDetails.tracpoint_coupon_id || '',
    url: postDetails.url || postDetails.link_url,
    verticals_list: postDetails.verticals_list || ''
  };
};

export const getPostMediaTypeFromQueryParams = (queryParams: { [key: string]: string }) => {
  return Object.entries(queryParams).reduce((acc: string[], curr) => {
    if (curr[0] === 'has_photo' && curr[1] === '1') acc = [...acc, curr[0]];
    if (curr[0] === 'has_link' && curr[1] === '1') acc = [...acc, curr[0]];
    if (curr[0] === 'has_copy' && curr[1] === '1') acc = [...acc, curr[0]];
    return acc;
  }, []);
};

export const getPostFavTagsFromQueryParams = (favTags: mgReducerTypes.IMediaGalleryTagsData[], queryTagsList: string) => {
  return queryTagsList.split(',').reduce((acc: (TagSuggestion & { favourite?: boolean })[], curr, index) => {
    const filteredTagObj = favTags.find((it) => it.favourite && it.name === curr);
    const updatedTagObj = filteredTagObj ? generateOptionsForReactTag([filteredTagObj]) : null;
    if (updatedTagObj?.[0]?.value) acc = updatedTagObj ? [...acc, updatedTagObj[0]] : [...acc];
    else acc = [...acc, { value: index + 1, label: curr, favourite: false }];
    return acc;
  }, []);
};

export const accountPostPermissionRestricted = (isAdmin: boolean, isFranchisor: boolean, isAccountFranchisee: boolean, franchiseePermissionValue: boolean) => {
  if (isAdmin || isFranchisor) {
    return false;
  } else if (isAccountFranchisee && franchiseePermissionValue) {
    return true;
  } else {
    return false;
  }
};

/** Get post content valid text field */
export const getPostContentValidText = (postDetails: Partial<postsApiTypes.IPostDetailSavedPost | postsApiTypes.IPostDetailContentPost>): postsContainerTypes.IPostsText => {
  return {
    facebook_text: postDetails.use_facebook ? postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK) : '',
    instagram_text: postDetails.use_instagram ? postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM) : '',
    twitter_text: postDetails.use_twitter ? postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER) : '',
    linkedin_text: postDetails.use_linkedin ? postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN) : '',
    tiktok_text: postDetails.use_tiktok ? postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK) : '',
    google_text: postDetails.use_google ? postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE) : '',
    pinterest_text: postDetails.use_pinterest ? postPlatformText(postDetails, COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST) : ''
  };
};

export const canEdit = (postData: postsContainerTypes.IContentPostDetail, franchisorFullAccess: boolean) => {
  if (postData?.disabled) {
    return false;
  } else {
    return Boolean(
      (Number(postData?.status_id) === 1 || franchisorFullAccess) &&
        postData?.moderation_status &&
        ['moderation_not_required', 'moderation_approved', 'moderation_rejected'].includes(postData?.moderation_status)
    );
  }
};

export const canApprove = (postData: postsContainerTypes.IContentPostDetail, franchisorFullAccess: boolean) => {
  if (postData?.disabled) {
    return false;
  } else {
    return Boolean(franchisorFullAccess && postData?.moderation_status && ['moderation_not_required', 'moderation_approved'].includes(postData?.moderation_status));
  }
};

export const atLeastApproved = (statusId: number) => {
  if (statusId) return statusId >= 3;
  return false;
};

export const canBeScheduled = (postData: postsContainerTypes.IContentPostDetail, userOwnership: string) => {
  const startDate = postData?.start_date;
  const endDate = postData?.end_date;
  const todayDate = getTodayDate('Etc/UTC', CALENDAR_YR_MN_DATE_FORMAT);
  const sixtyDaysFromToday = formatDate(convertMomentToDate(getMomentBasedOnTimezone('Etc/UTC')), 'addDay', 59, CALENDAR_YR_MN_DATE_FORMAT);
  if ((userOwnership === USER_OWNERSHIP.FRANCHISOR && postData?.account?.id && postData?.disabled) || !startDate || !endDate) {
    return true;
  }
  const excludedByStartDate = startDate > sixtyDaysFromToday;
  const excludedByEndDate = endDate < todayDate;
  return !excludedByStartDate && !excludedByEndDate;
};

export const canScheduleAccountPost = (postData: postsContainerTypes.IContentPostDetail, isAdmin: boolean, isFranchisor: boolean, isFranchisee: boolean, overriddenCustomPostsLocked: boolean) => {
  const customPostsRestricted = accountPostPermissionRestricted(isAdmin, isFranchisor, isFranchisee, overriddenCustomPostsLocked);
  if (customPostsRestricted && !atLeastApproved(postData.status_id)) {
    return false;
  } else {
    return true;
  }
};

export const getPostStatsReqPayload = (
  id: number,
  userOwnership: string,
  queryParams: { [key: string]: string },
  postDateRange: string[],
  isAvailBoostedPosts: boolean
): postsApiTypes.IPostListRequest => {
  return {
    id,
    userOwnership,
    ...(queryParams?.tags_list?.length && { tags: queryParams.tags_list.split(',') }),
    ...(queryParams?.text && { text: queryParams?.text }),
    isBoostedPosts: isAvailBoostedPosts,
    ...(postDateRange.length && { dateRange: postDateRange })
  };
};

export const getPostListReqPayload = (
  id: number,
  userOwnership: string,
  queryParams: { [key: string]: string },
  postDateRange: string[],
  isAvailBoostedPosts: boolean,
  currentPage: number,
  lastPostId?: number
): postsApiTypes.IPostListRequest => {
  return {
    id,
    userOwnership,
    ...(queryParams?.platform === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
      ? {
          has_facebook: 1
        }
      : queryParams?.platform === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
      ? {
          has_instagram: 1
        }
      : queryParams?.platform === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE
      ? {
          has_google: 1
        }
      : queryParams?.platform === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN
      ? {
          has_linkedin: 1
        }
      : queryParams?.platform === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
      ? {
          has_twitter: 1
        }
      : queryParams?.platform === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK
      ? {
          has_tiktok: 1
        }
      : queryParams?.platform === COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST && {
          has_pinterest: 1
        }),
    ...(!queryParams?.status
      ? { status_id: [] }
      : {
          ...(queryParams?.status === POSTS_STATUS_TYPE.REJECTED
            ? { rejected: queryParams?.status === POSTS_STATUS_TYPE.REJECTED ? 1 : 0, status_id: [1] }
            : {
                status_id:
                  queryParams?.status === POSTS_STATUS_TYPE.AI_GENERATED
                    ? [5]
                    : queryParams?.status === POSTS_STATUS_TYPE.APPROVED
                    ? [3]
                    : [POSTS_STATUS_TYPE.AWAITING_APPROVAL, POSTS_STATUS_TYPE.AA_MY_CONTENT].includes(queryParams?.status)
                    ? [2]
                    : [1],
                ...(queryParams?.status === POSTS_STATUS_TYPE.DRAFT && { rejected: 0 })
              })
        }),
    ...(queryParams?.ai_post === AIPostType.AI_ASSISTED && { ai_post: 1 }),
    ...(queryParams?.ai_post === AIPostType.NON_AI_ASSISTED && { ai_post: 0 }),
    ...(queryParams?.usage === PostUsageType.USED && { published: 1 }),
    ...(queryParams?.usage === PostUsageType.UNUSED && { published: 0 }),
    ...(queryParams?.has_photo && { has_photo: 1 }),
    ...(queryParams?.has_link && { has_link: 1 }),
    ...(queryParams?.has_copy && { has_copy: 1 }),
    ...(lastPostId && { after: lastPostId }),
    ...(queryParams?.tags_list?.length && { tags: queryParams.tags_list.split(',') }),
    ...(queryParams?.text && { text: queryParams?.text }),
    isBoostedPosts: isAvailBoostedPosts,
    ...(postDateRange.length && { dateRange: postDateRange }),
    page: lastPostId ? currentPage + 1 : 1,
    ...(queryParams?.campaign_id && { campaign_id: queryParams?.campaign_id }),
    ...(queryParams?.content_supplier_id && { cs_franchisor_id: queryParams?.content_supplier_id }),
    ...(queryParams?.source && { source: queryParams.source }),
    ...(queryParams?.syndicated_to_any_franchisor && { syndicated_to_any_franchisor: queryParams?.syndicated_to_any_franchisor }),
    ...(queryParams?.syndicated_to_any_account && { syndicated_to_any_account: queryParams?.syndicated_to_any_account })
  };
};

export const isSocialMediaActive = (activePlatforms: string[], platform: string) => {
  return activePlatforms.includes(platform);
};

export const postPlatformText = (currentPostDetails: Partial<postsReducerTypes.IPostDetailsData | calendarApiTypes.IGetScheduledPostResponse>, tabName: string, savedHashtags?: string[]): string => {
  let resultText: string = '';
  if (currentPostDetails && currentPostDetails[`use_${tabName}`]) {
    if (tabName === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER) {
      resultText = !currentPostDetails.twitter_text ? currentPostDetails?.short_text || '' : currentPostDetails.twitter_text || '';
    } else if (
      !currentPostDetails.facebook_text &&
      !currentPostDetails.instagram_text &&
      !currentPostDetails.linkedin_text &&
      !currentPostDetails.google_text &&
      !currentPostDetails.tiktok_text &&
      !currentPostDetails.pinterest_text
    ) {
      resultText = currentPostDetails?.long_text || '';
    } else {
      resultText = currentPostDetails[`${tabName}_text`] || '';
    }
    if (savedHashtags?.length) {
      const textAndHashTags = splitTextAndHashtagsFromText(resultText);
      resultText = `${textAndHashTags.text}${['\n', ' '].includes(textAndHashTags.text[textAndHashTags.text.length - 1]) ? '' : ' '}${[...new Set(textAndHashTags.hashTag.concat(savedHashtags))].join(
        ' '
      )}`;
    }
    return resultText;
  }
  return '';
};

export const getRemovePostFromCampaignsConfirmMsg = (campaignsCount: number) => {
  return `Are you sure you want to remove this post from campaign${campaignsCount > 1 ? 's' : ''}?`;
};

export const convertFBTextToEditorState = (text: string, mentions: MentionData[]) => {
  const contentState = ContentState.createFromText(text);
  let editorState = convertStringToEditorState(text);
  let newContentState = contentState;
  let blockContents = newContentState.getBlockMap().toJS();
  if (mentions?.length) {
    mentions.forEach((mention) => {
      const selectedBlock = Object.keys(blockContents).find((block) => blockContents[block]?.text.includes(`@[${mention?.id}]`));
      const selectedBlockOffset = selectedBlock ? blockContents[selectedBlock]?.text.indexOf(`@[${mention?.id}]`) : null;
      if (selectedBlock) {
        // Select offset position to replace the mention id into mention name
        const selection = SelectionState.createEmpty(selectedBlock).merge({
          anchorOffset: selectedBlockOffset, // start of the offset
          focusOffset: selectedBlockOffset + `@[${mention.id}]`.length // end of the offset
        });
        // Added links into editor state
        const entityKey = newContentState.createEntity('mention', 'SEGMENTED', { mention }).getLastCreatedEntityKey();
        newContentState = Modifier.replaceText(newContentState, selection, mention.name, undefined, entityKey);
        // Update the editor state with links
        editorState = EditorState.push(editorState, newContentState, 'insert-characters');
        // Update the block content property with updated text to avoid duplicate find
        blockContents = newContentState.getBlockMap().toJS();
      }
    });
  }
  return editorState || createEmptyEditorState();
};

export const generatePostSyndicationTableData = (data: apFranReducerTypes.IPostSyndicationDetails[], lastSyndicateDate: any) => {
  const groupedData: Record<string, { date: string; syndicated_brands: string[]; country_codes: string[] }> = {};
  data.forEach((item) => {
    const dateObj = (getFormatBasedOnExactDate(item?.created_at, LOCATION_TIME_ZONE_DATE_TIME_FORMAT) as string) || lastSyndicateDate;
    if (!groupedData[dateObj]) {
      groupedData[dateObj] = {
        date: dateObj,
        syndicated_brands: [],
        country_codes: []
      };
    }
    groupedData[dateObj].syndicated_brands.push(item?.franchisor_name);
    groupedData[dateObj].country_codes = [...new Set([...groupedData[dateObj]?.country_codes, ...item?.country_codes])];
  });

  return Object.values(groupedData);
};
